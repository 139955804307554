import { TableDataRow } from '../../Component/Table/interface';
import { SignInUser } from '../../Interface/SignInUser';
import { del, get, post, postFile, postAxios, put, getCSV } from './base-api.service';


//user signin
export const signin = (user: SignInUser) => {
  return post(`/users/login`, user);
};

//validate user session
export const validate = () => {
  return get(`/users/login/token`);
};

//user logout
export const userLogout = () => {
  return post(`/users/logout`);
};

//get all sidebar main menu info
export const getAllFolders = (type: string) => {
  return get(`/${type}/folders`);
};

//get all boards language for single
export const getAllLanguageBoards = (
  language: string,
  type: string
) => {
  return get(`/${type}/boards/${language}`);
};

//get all boards for a single folder
export const getBoardsByFolder = (
  folder: number | string,
  type: string
) => {
  return get(`/${type}/boards/${folder}`);
};

//get all boards language for single
export const getSingleBoard = (id: string, type: string, filters: any[] = []) => {
  return post(`/${type}/board/${id}`, {
	filters: filters
  });
};

//sidebar search
export const sideBarApiSearch = (pattern: string, type: string) => {
  return post(`/${type}/search/board`, { pattern: pattern });
};

export const getStash = (filters: any[]) => {
	return post(`/stash`, {
		filters: filters
	})
}

export const getAllWebsites = (filters: any[]) => {
	return post(`/all_websites`, {
		filters: filters
	})
}

export const createOrderFinalBoard = (data: string) => {
	return post(`/order_final/create`, {
		data: data
	})
};

export const updateTableRow = (data: TableDataRow, type: string, id?: number) => {
	return post(`/update/${type}` + (id ? `/${id}` : ''), {
		row: data
	})
};

export const getMondayStatus = () => {
	return get(`/synchronization/monday_status`);
};

export const changeMondayStatus = () => {
	return get(`/synchronization/change_monday_status`);
};

// start synchronization
export const startSynchronization = () => {
  return post(`/synchronization`);
};

//get filter data
export const getFilterData = (
  data: any,
  current: any,
  board: string
) => {

  return post(`/links_availability/filters/${board}`, {
    filters: data,
    current: current,
  });
};

//check user authenticated or not
export const isAuthenticate = () => {
  if (typeof window === "undefined") {
    return false;
  }
  if (localStorage.getItem('auth_token')) {
    return true;
  } else {
    return false;
  }
};


//get user Infos
export const getUserInfo = () => {
  return get(`/users`);
};


//add new user
export const addNewUser = (user: any) => {
  return post(`/user/add`, user);
};


//update user
export const updateUser = (id: string, user: any) => {
  return put(`/user/${id}`, user);
};
//delete user
export const deleteUser = (id: string) => {
  return del(`/user/${id}`);
};
//update user password
export const updateUserPassword = (id: string, password: string) => {
  return put(`/user/password/${id}`, { "password": password });
};

//get state info
export const getStateInfo = () => {
  return get(`/synchronization/states`);
};

export const uploadCSV = (type: string, data: FormData) => {
  return postFile(`/${type}/import`, data);
}

export const downloadCSVSample = (type: string) => {
  return getCSV(`/${type}/csv_sample`);
}

// get all links availability data
export const allLinksAvailability = () => {
  return get(`/links_availability`);
};

// create all links availability excel
export const excelLinksAvailability = (
  board: any
) => {
  return postAxios(`/links_availability/excel`, board);
};

// reset password
export const resetPassword = (email: string) => {
  return post(`/forgot-password`, { "email": email });
};
// reset password
export const changePassword = (token: string, password: string) => {
  return post(`/reset-password`, { "token": token, "password": password });
};


// view
export const views = (finaldata: any) => {
  return post(`/views`, finaldata);
};

// view id
export const getViews = (type: string) => {
  return get(`/views/${type}`);
};

// Remove view
export const removeView = (view_id: number) => {
  return del(`/views`, { view_id: view_id });
};

// Rename view
export const renameViewApi = (id: number, rename: string) => {
  return put(`/name/views`, { view_id: id, name: rename });
};