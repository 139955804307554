import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
interface MapingProps {
    viewSearch: (value: any, index: number, name: string) => Promise<void>;
    setRenameViewId: (value: any) => void;
    setDeleteViewId: (value: any) => void;
    isDataActive: number;
    v: any
    i: number,
	disabled: boolean
}
const ViewData = ({ v, i, isDataActive, viewSearch, setRenameViewId, setDeleteViewId, disabled }: MapingProps) => {

    return (
        <>
            <button className='viewBtn' disabled={disabled} onClick={() => viewSearch(v.filterOptions, i, v.name)}>{v.name}</button>
            {disabled ? null : <span className='editButton' onClick={() => setRenameViewId(v.view_id)} data-bs-toggle="modal" data-bs-target="#staticBackdrop"><FontAwesomeIcon icon={faPen} /></span>}
			{disabled ? null : <span className='crossButton' onClick={() => setDeleteViewId(v.view_id)} data-bs-toggle="modal" data-bs-target="#confirmDelete"><FontAwesomeIcon icon={faCircleXmark} /></span>}
        </>
    )
}

export default ViewData