import React, { useEffect, useState, useRef } from 'react';
import './TableC.css';
import { Dropdown } from 'react-bootstrap';
import {
  uploadCSV, getUserInfo, downloadCSVSample
} from '../../shared/services/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faUpload,
  faDownload,
  faCompress,
  faArrowDownUpAcrossLine,
  faEllipsis,
  faChevronDown,
  faChevronUp,
  faPencil
} from '@fortawesome/free-solid-svg-icons';
import {
  FilterColOptions,
  TableColumn,
  TableColumns,
  TableDataResponse,
  TableDataRow,
  GroupedTableData,
  userMap
} from './interface';
import { SORT_TYPE } from './enum';
import * as XLSX from 'xlsx';
import TableData from './TableData';
import FiltersC from './FiltersC';
import SearchData from './SearchData';
import RowEditor from './RowEditor';
import { useDebounce } from "use-debounce";
import {
	useFetchData
  } from '../../shared/hooks/useFetchData';
import dayjs from "dayjs";
let advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)


interface tableProps  {
	type: string;
	useFilters: boolean;
	useGroups?: boolean;
	editable?: boolean;
	allowUpload?: boolean;
	columns: Array<TableColumn>;
	id?: string | number;
}

interface userData {
	id: number,
	name: string,
	role: string,
	email: string,
	funnels: boolean
}

const TableC = (props: tableProps) => {
  // const ref = useRef(null);
  const [boardName, setBoardName] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(true);
  const [searchLoading, setSearchLoading] = React.useState<boolean>(true);
  const [showUpload, setShowUpload] = useState<boolean>(false);
  const [csvFile, setCSVFile] = useState<File>();
  const uploaderRef = useRef(null);
  const [uploadError, setUploadError] = useState<boolean>(false);
  const [uploadInProgess, setUploadInProgess] = useState<boolean>(false);
  const [uploadNoteText, setUploadNoteText] = useState<string>('');

  const [loadTable, setLoadTable] = React.useState<boolean>(true);
  const [subTableStatus, setSubTableStatus] = React.useState<{[group: string] : boolean}>({});
  const [columns, setColumns] = useState<TableColumns>({});
  const [nameSortType, setNameSortType] = React.useState<SORT_TYPE>(
    SORT_TYPE.NA
  );
  const [tableSearchText, setTableSearchText] = useState<string>('');
  const [tableSearchTextTemp, setTableSearchTextTemp] = useState<string>('');
  const [debouncedTableSearchText] = useDebounce(tableSearchText, 500);
  // user role
  const [userInfo, setUserInfo] = useState<any>();
  const [userList, setUserList] = useState<userMap>({});
  //new
  const [tableData, setTableData] = React.useState<TableDataRow[] | GroupedTableData>(
    []
  );
  //TODO EVG Check why this is used at all??
  const [backUptableData, setBackUpTableData] = React.useState<
  TableDataRow[] | GroupedTableData
  >([]);

  const [tableFilterColumns, setTableFilterColumns] = React.useState<
    FilterColOptions[]
  >([]);

  const [selectedRow, setSelectedRow] = useState<TableDataRow|null>(null);

  //sort column
  const sortColumn = async (colData: TableColumn, index: number) => {
    let temColState = {...columns};
    let tempColElement = { ...temColState[colData.id] };

    let sortType = SORT_TYPE.NA;
    if (
      colData.sort === SORT_TYPE.NA ||
      colData.sort === SORT_TYPE.DESC
    ) {
      sortType = SORT_TYPE.ASCE;
      tempColElement.sort = SORT_TYPE.ASCE;
    } else if (colData.sort === SORT_TYPE.ASCE) {
      sortType = SORT_TYPE.DESC;
      tempColElement.sort = SORT_TYPE.DESC;
    }
    temColState[tempColElement.id] = tempColElement;
    setColumns(temColState);

	if (props.useGroups) {
		let sortedData: GroupedTableData = {};
		for (let subTableName in tableData) {
			sortedData[subTableName] = await sortData((tableData as GroupedTableData)[subTableName], colData, sortType);
		}
		setLoadTable(false);
		setTableData(sortedData);
	} else {
		let sortedData: TableDataRow[] = [];
		sortedData = await sortData(tableData as TableDataRow[], colData, sortType);
		setLoadTable(false);
		setTableData(sortedData);
	}
  };

  const sortData = async (data: TableDataRow[], colData: TableColumn, sortType: string) => {
	let sorted = await data.sort((a, b) => {
		setLoadTable(true);
  
		let op_1 = a[colData.id];
		let op_2 = b[colData.id];
		let value_1;
		let value_2;
		if (colData.type !== 'number') {
		  //EVG NOTE: The sort implementataion might need to be reviewd
		  op_1 = (op_1 as string).replace(/[$£¥€₪,]/g, '');
		  op_2 = (op_2 as string).replace(/[$£¥€₪,]/g, '');
  
  
		  if (op_1 === '-') {
			  op_1 = '';
		  }
		  if (op_2 === '-') {
			  op_2 = '';
		  }
  
		  if (op_1 === 'null' || op_1 === 'Free') {
			  return sortType === SORT_TYPE.ASCE ? -1 : 1;
		  }
		  if (op_2 === 'null' || op_2 === 'Free') {
			  return sortType === SORT_TYPE.ASCE ? 1 : -1;
		  }
		  value_1 = op_1 === null ? '' : op_1;
		  value_2 = op_2 === null ? '' : op_2;
  
		} else {
		  value_1 = op_1 === null ? 0 : new Number(op_1);
		  value_2 = op_2 === null ? 0 : new Number(op_2);
		}
		  if (value_1 > value_2) {
			  return sortType === SORT_TYPE.ASCE ? 1 : -1;
		  } else if (value_2 > value_1) {
			  return sortType === SORT_TYPE.ASCE ? -1 : 1;
		  } else {
			  return 0;
		  }
	  });
	  return sorted;
  }

  const hideColumn = async (colData: TableColumn) => {

    let data = {...columns};
	let newColumnData: TableColumn = {
		...colData,
		visible: !colData.visible
	}
	data[newColumnData.id] = newColumnData;
    setColumns(data);
  }

  const sortByName = async (data: TableDataRow[], sortType: string) => {
	let sorted = await data.sort((a, b) => {
		setLoadTable(true);
  
		let op_1 = a.name;
		let op_2 = b.name;
  
		if (op_1 > op_2) {
		  return sortType === SORT_TYPE.ASCE ? 1 : -1;
		} else if (op_2 > op_1) {
		  return sortType === SORT_TYPE.ASCE ? -1 : 1;
		} else {
		  return 0;
		}
	  });
	return sorted;  
  }

  //sort name Column
  const sortNameColumn = async () => {
	let sortType = SORT_TYPE.NA;
	if (
		nameSortType === SORT_TYPE.NA ||
		nameSortType === SORT_TYPE.DESC
	  ) {
		setNameSortType(SORT_TYPE.ASCE);
		sortType = SORT_TYPE.ASCE;
	  } else {
		setNameSortType(SORT_TYPE.DESC);
		sortType = SORT_TYPE.DESC;
	  }
	if (props.useGroups) {
		let sortedData: GroupedTableData = {};
		for (let subTableName in tableData) {
			let tempData = (tableData as GroupedTableData)[subTableName];
			sortedData[subTableName] = await sortByName(tempData, sortType);
		}	
		setTableData(sortedData);
		setLoadTable(false);
	} else {
		let tempData = tableData as TableDataRow[];
		let sortedData = await sortByName(tempData, sortType);
		setTableData([...sortedData]);
		setLoadTable(false);
	}
  };


  //dynamic column name
	const renderDynamicColumn = () => {
		return Object.values(columns).map((colData, i) => {
			if (colData.id !== 'name') {
				return (
					<th key={`tableDataColumns_${i}`}>
						<div className='flexcol'>
						<div>{colData.visible === true ? String(colData.label) + ' ' : ' '}</div>
						<div className="dropdown">
							<button className="dotBtn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
							<FontAwesomeIcon icon={faEllipsis} />
							</button>

							<ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
							<li>
								<div className="dropdown-item">
								{colData.visible === true
									? (
									<button className='btn' onClick={() => hideColumn(colData)}><FontAwesomeIcon icon={faCompress} /> <span>Collapse</span></button>
									) : (
									<button className='btn' onClick={() => hideColumn(colData)}><FontAwesomeIcon icon={faCompress} /> <span>Uncollapse</span></button>
									)}
								</div>
							</li>
							<li>
								<div className="dropdown-item" onClick={async () => { await sortColumn(colData, i); }}>
								{colData.sort === SORT_TYPE.NA ||
									colData.sort === SORT_TYPE.DESC ? (
									<button className='btn'><FontAwesomeIcon icon={faArrowDownUpAcrossLine} /> <span>Sort</span></button>
								) : (
									<button className='btn'><FontAwesomeIcon icon={faArrowDownUpAcrossLine} /> <span>Sort</span></button>
								)}
								</div>
							</li>
							</ul>
						</div>
						</div>

					</th>
				)
			} else return (<></>);
		})
	};

const processCellValue = (cellValue: string | number | null, colId: string) : string | number | null  => {
	let proccedValue = cellValue;
	if (columns[colId].type === 'number') {
		proccedValue = columns[colId].subType === 'percent' ? 
		Intl.NumberFormat('en-US', {
			style: 'percent', 
			minimumFractionDigits: 2, 
			maximumFractionDigits: 2}
			).format((cellValue as number) / 100) : 
		Intl.NumberFormat().format(cellValue as number);
	}
	else if (columns[colId].type === 'enum') {
		proccedValue = columns[colId].map?.[cellValue as number | string] as string;
	}
	else if (columns[colId].type === 'date') {
		proccedValue = dayjs(cellValue).format('MMMM Do YYYY, HH:mm:ss');
	}
	else if (columns[colId].type === 'user') {
		proccedValue = cellValue ? userList[cellValue as number] : null;
	}
	return proccedValue;
}  
	
const tableDataList = (data:TableDataRow[] ) => {
    return data.map((row, i) => {
      return (

        <tr key={`tableData_${i}`}>
		  {props.editable ? (
		  <th className="sticky-cell editCell">
			<FontAwesomeIcon
				icon={faPencil}
				className="edit-icon"
				onClick={() => editRow(row, i)}
			/>
		  </th>) : null}	
          <th className="sticky-cell">{row.name} </th>
          {<>{Object.keys(columns).map((key, j) => {
            if (columns && columns[key] && key !== 'name') {
              if (columns[key] && columns[key].visible === false) {
                return <th key={`result_${i}_${j}`}>.</th>
              } else {
                if (row[key] === 'null' || row[key] === null) {
                  return <th key={`result_${i}_${j}`}></th>;
                } else {
				  let cellValue = processCellValue(row[key], key);
                  if (columns[key].type === 'string' && row[key] && (row[key] as string).length > 10) {
                    return (
                      <th key={`result_${i}_${j}`} className="tooltipp">
                        {(cellValue as string).substring(0, 10) + '...'}
                        <span className="tooltipptext">
							{cellValue}
                        </span>
                      </th>
                    );
                  } else {
                    return <th key={`result_${i}_${j}`}>{cellValue}</th>;
                  }
                }
              }
            }
          })}

          </>}

        </tr>
      );
    });

  };

  //search table data
  const searchTableData = () => {
	  let searchString = debouncedTableSearchText.toLowerCase();
	  if (props.useGroups) {
		let filterdData: GroupedTableData = {};
		for (let subTableName in backUptableData) {
			filterdData[subTableName] = (backUptableData as GroupedTableData)[subTableName].filter((item: TableDataRow) => {
				return JSON.stringify(item)
				  .toLowerCase()
				  .includes(searchString);
			  });
		}
		setTableData(filterdData);
	  } else {
		let result = (backUptableData as TableDataRow[]).filter((item: TableDataRow) => {
			return JSON.stringify(item)
			  .toLowerCase()
			  .includes(searchString);
		  });
		  setTableData([...result]);
	  }	
	  setLoadTable(false); 
  };

  useEffect(() => {
	searchTableData();
	// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedTableSearchText])

  const [usedFilters, setUsedFilters] = useState<any[]>([]);
  const {data, doFetch} = useFetchData();
 
  const processData = (data: TableDataResponse) => {
	setBoardName(data.board_name);
	setTableData(data.rows);
	setBackUpTableData(data.rows);
	if (props.useGroups) {
		const initialStatus : {[group: string] : boolean} = {};
		for (const groupName in data.rows) {
			initialStatus[groupName as string] = true;
		}
		setSubTableStatus(initialStatus);
	}
	setSearchLoading(false);
	setLoadTable(false);
  }

  const processColumns = () => {
	let filterTempData: FilterColOptions[] = [];
	let columnObject: TableColumns = {};
	props.columns.forEach((col) => {
		columnObject[col.id] = {
			...col,
			sort: col.hasOwnProperty('sort') ? col.sort : SORT_TYPE.NA,
			visible: col.hasOwnProperty('visible') ? col.visible : true 
		};
		filterTempData.push({
            value: col.id,
            label: col.label,
            // id: col.id,
            type: col.type
		});
	})
	setColumns(columnObject);
	setTableFilterColumns(filterTempData);
  }

  useEffect(() => {
	let isMounted = true;
	if (isMounted) {
		processColumns();
	}
	return () => {
		isMounted = false;
	}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.type]);

  useEffect(() => {
	let isMounted = true;
	setTableData([]);
	const asyncFetch = async () => {
		await doFetch(props.type, props.id as string | number, []);
	}
	if (props.id?.toString().length) {
		if (isMounted) {
			setLoadTable(true);
			asyncFetch();
		}
		
	}
	return () => {
		isMounted = false;
	}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  useEffect(() => {
	let isMounted = true;
	if (isMounted) {
		processData(data);
		setLoading(false);
		setSearchLoading(false);
	}
	return () => {
		isMounted = false;
	}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  //download the file
  const dataDownload = () => {
	if (tableData.length) {
		const processedData: TableDataRow[]  = [];		
		for (const row of tableData as TableDataRow[]) {
			let processedRow = {...row};
			for (const colId in processedRow) {
				if (columns.hasOwnProperty(colId)) {
					processedRow[colId] = processCellValue(processedRow[colId], colId);
				}
			}
			processedData.push(processedRow);
		}
		let dataRow = (tableData as TableDataRow[])[0];
		let colNames = getExportColumnNames(dataRow);
		const wb = XLSX.utils.book_new();
		const ws = XLSX.utils.json_to_sheet(processedData as TableDataRow[]);
		XLSX.utils.sheet_add_aoa(ws, [colNames], { origin: "A1" });
		XLSX.utils.book_append_sheet(wb, ws, 'Export');
		let currDate = new Date().toISOString();
		let fileName = props.type  + '_' + boardName + '_' + currDate.substring(0, 10) + '.xlsx';
		XLSX.writeFile(wb, fileName);
	}

  };

  const dataDownloadGrouped = () => {
	let dataGroups = Object.keys(tableData);
	if (dataGroups.length) {
		const firstGroup = dataGroups[0];
		let dataRow = (tableData as GroupedTableData)[firstGroup][0];
		let colNames = getExportColumnNames(dataRow);
		colNames.push('Group');
		const wb = XLSX.utils.book_new();
		const sheetData: Array<Array<string | number | null>> = [colNames];
		for (const subTableName in tableData) {
			for (const groupData of (tableData as GroupedTableData)[subTableName]) {
				sheetData.push(Object.values(groupData).concat([subTableName]));
			}
		}
		const ws = XLSX.utils.aoa_to_sheet(sheetData);
		XLSX.utils.book_append_sheet(wb, ws, 'Export');
		let currDate = new Date().toISOString();
		let fileName = props.type  + '_' + boardName + '_' + currDate.substring(0, 10) + '.xlsx';
		XLSX.writeFile(wb, fileName);
	}
  };

  const getExportColumnNames = (dataRow: TableDataRow) => {
	let colNames = [];
	for (const colId in dataRow) {
		if (columns.hasOwnProperty(colId)) {
			colNames.push(columns[colId].label);
		} else {
			colNames.push(colId);
		}	
	}
	return colNames;
  }

  // User parsing
  useEffect(() => {
	let isMounted = true;
    let user = localStorage.getItem('user_info');

    if (user && isMounted) {
	  const parsedUser = JSON.parse(user);
	  if (parsedUser.role_id === 2) {
		let userList: userMap = {};
		getUserInfo().then((beUsers) => {
			beUsers.data.forEach((userData: userData) => {
				userList[userData.id] = userData.name;
			});
			setUserList(userList)
		})
	  }
      setUserInfo(parsedUser);
    }
	return () => {
		isMounted = false;
	}
  }, []);

  useEffect(() => {
	let isMounted = true;
	if (isMounted) {
    	setTableSearchText(tableSearchTextTemp);
		setLoadTable(true);
	}
	return () => {
		isMounted = false;
	}
  }, [tableSearchTextTemp])



  const onChangeFilters = async (filterOptions: any[]) => {
	setLoadTable(true);
	setUsedFilters(filterOptions);
	await doFetch(props.type, props.id as string | number, filterOptions);
  }

  const handleSubTableToggle = (key: string) => {
	const updatedStatus = {
		...subTableStatus
	}
	updatedStatus[key] = !updatedStatus[key];
	setSubTableStatus(updatedStatus);
  }

  function handleCSVUpload(e: React.ChangeEvent<HTMLInputElement>) {
	if (e.target && e.target.files && e.target.files[0]) {
		const file = e.target.files[0];
		console.log('csv test', file);
		if (file.type !== 'text/csv') {
			setCSVFile(undefined);
			setUploadError(true);
			setUploadNoteText('File must use a CSV format');
		}
		else if (file.size > 5242880) {
			setCSVFile(undefined);
			setUploadError(true);
			setUploadNoteText('File size is too large');
			return;
		} else {
			setUploadError(false);
			setUploadNoteText('');
			setCSVFile(file);
		}
	} else {
		setCSVFile(undefined);
		setUploadNoteText('');
	}
  }

  const uploadCSVFile = async () => {
	if (csvFile && !uploadError) {
		const formData = new FormData();
		formData.append("file", csvFile);
		if (props.type === 'order_final') {
			formData.append("board_id", props.id as string);
		}
		setUploadNoteText('Uploading..');
		setUploadInProgess(true);
		uploadCSV(props.type, formData).then((res) => {
			setCSVFile(undefined);
			if (res.error) {
				setUploadError(true);
				setUploadNoteText(res.message);
			} else {
				setLoadTable(true);
				setUploadNoteText('File Uploaded, refreshing..')
				setCSVFile(undefined);
				if (uploaderRef.current) {
					(uploaderRef.current as HTMLInputElement).value = '';
				}
				setUsedFilters([]);
				doFetch(props.type, props.id as string | number, []).then(() => {
					setLoading(false);
					setSearchLoading(false);
					setUploadNoteText('')
				});
			}
			setUploadInProgess(false);
		})
	}
  }

  const downloadSample = async () => {
	await downloadCSVSample(props.type).then((res) => {
		const fileName = props.type + '_sample.csv';
		let blob = new Blob([res], { type: 'text/csv;charset=utf-8;' });
	
		if ((window.navigator as any).msSaveBlob) { // IE 10+ support
			(window.navigator as any).msSaveBlob(blob, fileName);
		} else {
			let link = document.createElement("a");
			if (link.download !== undefined) {
				let url = URL.createObjectURL(blob);
				link.setAttribute("href", url);
				link.setAttribute("download", fileName);
				link.style.visibility = 'hidden';
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		}
	})
  }

  const editRow = (row: TableDataRow, idx: number) => {
	setSelectedRow(row);
  }

  const onEditRow = async () => {
	setSelectedRow(null);
	doFetch(props.type, props.id as string | number, usedFilters);
  }

  return (
      <>
          {searchLoading ? <div className="text-center filterImage">
            <img src="/Spinner-1s-200px.gif" alt="" />
          </div> : null}
          <div className='col-md-12'>
            <div className='boardname'><p className="tableHeading text-center">{boardName}</p></div>
          </div>
          <div className='col-md-12 filterPanel'>
            <div className='d-flex justify-content-between'>
              <div className="leftElement">
				<ul>
					<li className="searchBox">
						<FontAwesomeIcon
							icon={faSearch}
							className="search-icon"
						/>
						<SearchData tableSearchTextTemp={tableSearchTextTemp} setTableSearchTextTemp={setTableSearchTextTemp} />
						{props.useFilters ? 
							<FiltersC dataLoading={loadTable} tableId={props.id} type={props.type} columns={tableFilterColumns} onChangeFilters={onChangeFilters} tableData={tableData as TableDataRow[]} /> : '' }
					</li>
				</ul>		
              </div >

              <div className="rightElement">
                <ul>
                  <li>
                    <button
                      className="user-name btn exportBtn"
                      type="button"
                      onClick={() => props.useGroups ? dataDownloadGrouped() : dataDownload()}
                    >
                      <FontAwesomeIcon
                        icon={faDownload}
                        className="user-icon"
                      />{' '}
                      Export
                    </button>
                  </li>
                  {props.allowUpload ? (
				  <li>
					<Dropdown show={showUpload} onToggle={(isOpen) => setShowUpload(isOpen)} id='csvUploadPanel'>
						<Dropdown.Toggle
							className="user-name btn importBtn"
						>
						<FontAwesomeIcon
							icon={faUpload}
							className="user-icon"
						/>{' '}
						Upload CSV
						</Dropdown.Toggle>
						<Dropdown.Menu align={'end'}>
							<div className="csvUploader">
								<div className="container mb-3">
									<div className="row uploaderHeading">
										<div className="col-md">
											Select CSV file to upload (Max 5MB):
										</div>
									</div>
									<div className='row'>
										<div className='col csv-warning'>
											Note: Must be a valid CSV file with a single table. The first row should contain the column names from the sample file.
										</div>
									</div>
									{uploadNoteText ? (<div className={'row uploadNote' + (uploadError ? ' uploadError' : '')}>
										<div className="col">
											{uploadNoteText}
										</div>
									</div>) : ''}	
									<div className="row mb-3 mt-2">
										<div className="col-md">
											<input ref={uploaderRef} type='file' accept='.csv' id='csvFileUploader' onChange={(e) => handleCSVUpload(e)} />
										</div>
									</div>
									<div className="row m justify-content-end">
										<div className='col tableBtnCont'>
											<button
												className="user-name btn sampleBtn"
												type="button"
												onClick={downloadSample}
											>
												Download Sample
											</button>
											<button
												className="user-name btn uploadBtn"
												type="button"
												disabled={typeof csvFile === 'undefined' || csvFile?.size === 0 || uploadError || uploadInProgess}
												onClick={uploadCSVFile}
											>
												Upload File
											</button>
										</div>
									</div>
								</div>
							</div>
						</Dropdown.Menu>
					</Dropdown>
                    
                  </li>
				  ) : ''}
                </ul>
              </div>
            </div >
			<RowEditor type={props.type} columns={columns} userList={userList} dataRow={selectedRow} onEditRow={onEditRow} />
          </div >

          {
            loading ? (
              <div className="text-center loadingImage " >
                <img src="/Spinner-1s-200px.gif" alt="" />
              </div>
            ) : (
              <div>
                <div className="col-md-12">
                  <div className="tableSize">
                    {(loadTable) ? (
                      <div className="tableLoadingColor text-center">
                        <img src="/Spinner-1s-200px.gif" alt="" />
                      </div>
                    ) : null}
					{
						props.useGroups ?  Object.keys(tableData).map((key, index) => {
							return (
								<div className='groupCont' key={key}>
									<div className='subTableHeading'>
										{key}
										<span className='subTableToggle' onClick={() => handleSubTableToggle(key)}>
											<FontAwesomeIcon icon={subTableStatus[key] ? faChevronDown : faChevronUp} />
										</span>
										
									</div>
									<TableData show={subTableStatus[key]} editable={props.editable || false} type={nameSortType} data={(tableData as GroupedTableData)[key as string]} tableDataList={tableDataList} renderDynamicColumn={renderDynamicColumn} sortNameColumn={sortNameColumn} />
								</div>
							)
						}) 
						: 
						<TableData type={nameSortType} editable={props.editable || false} data={tableData as TableDataRow[]} show={true} tableDataList={tableDataList} renderDynamicColumn={renderDynamicColumn} sortNameColumn={sortNameColumn} />
					}
                  </div>
                </div>
              </div>
            )
          }

		</>
  );
};

export default TableC;
