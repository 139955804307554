import React from 'react';
import TableC from '../Component/Table/TableC';
import { TableColumn } from '../Component/Table/interface';
import { SORT_TYPE } from '../Component/Table/enum';

const columns : TableColumn[] = [
	{
		label: 'Name',
		id: 'name',
		type: 'string',
		sort: SORT_TYPE.ASCE
	},
	{
		label: 'Funnel Name',
		id: 'funnel_name',
		type: 'string',
	},
	{
		label: 'Activation Status',
		id: 'activation_status',
		type: 'string',
	},
	{
		label: 'Website Goal',
		id: 'goal',
		type: 'string',
	},
	{
		label: 'Language',
		id: 'language',
		type: 'string',
	},
	{
		label: 'Type',
		id: 'type',
		type: 'string',
	},
	{
		label: 'WebMaster',
		id: 'webmaster',
		type: 'user',
	}
];	

const AllWebsites = () => {
  
  return (
    <>	  
        {<TableC id={'all_websites'} type='all_websites' useFilters={true} editable={true} columns={columns} allowUpload={true} />}
    </>
  );
};

export default AllWebsites;
