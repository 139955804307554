import React, { useState, useEffect, useRef } from 'react';
import { Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import {
  ProSidebar,
  SidebarHeader,
  SidebarContent,
} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBarsStaggered,
  faBorderAll,
  faFolderOpen,
  faRectangleList
} from '@fortawesome/free-solid-svg-icons';
import './Sidebar.scss';
import { useHistory, Link, useParams } from 'react-router-dom';
import {
  getAllFolders,
  sideBarApiSearch,
  getBoardsByFolder
} from '../../shared/services/api';
// import Loader from "react-loader-spinner";
import LoadingOverlay from 'react-loading-overlay-ts';
import Select from 'react-select';
import {SingleValue, ActionMeta} from 'react-select'
import { useDebounce } from "use-debounce";
// added styles
const styles = {
  sideBarHeight: {
    height: '100vh',
  },
  menuIcon: {
    padding: '8px 20px 8px 20px',
    cursor: 'pointer',
    // textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    minWidth: '35px',
    minHeight: '65px',
  },
};
//menu interface
interface SubMenu {
  id: number;
  name: string;
}
interface Menu {
  id: number;
  name: string;
  created_at: string;
}

interface SubMenu {
  language: SubMenuData[];
}
interface SubMenuData {
  id: number;
  name: string;
  position?: number;
}

interface menuPageOption {
	label: string,
	value: string
}

//TODO movee this to a centralized location, consider using store
interface UserInfo {
	id: number,
	email: string,
	name: string,
	role_id: number,
}

interface boardData {
	type: 'single' | 'fodlers',
	name: string
}

interface Boards {
	[board_id: string]: boardData
}

interface RefreshSignal {
	refreshSidebar: boolean,
	openFolderId?: number,
	openFolderName?: string
}

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [menu, setMenu] = React.useState<Menu[]>([]);
  const [subMenu, setSubMenu] = React.useState<any>({});
  const history = useHistory();
  const [isActive, setActive] = useState(true);
  const [searchText, setSearchText] = React.useState<string>('');
  const [debouncedSearchText] = useDebounce(searchText, 500);
  const [sideShow, setSideShow] = useState(true);

  const [menuShow, setMenuShow] = useState(true);

  const [openSubMenus, setOpenSubMenus] = useState<{[key: number] : boolean}>({});
  const myRef = useRef<null | HTMLDivElement>(null);

  const boardMap: Boards = {
	stash: {
		type: 'single',
		name: 'Stash',
	},
	all_websites: {
		type: 'single',
		name: 'All Websites',
	},
  }

  let params = useParams<{boardType: string}>();

  const onClickMenuIcon = () => {
    setCollapsed(!collapsed);
    setSideShow(!sideShow);
    setMenuShow(false);
  };

  const redirectToLogin = () => {
	localStorage.removeItem('user_info');
	localStorage.removeItem('auth_token');
	history.push(`/`);
  }

  function openFolder(name: string, id: number, forceOpen: boolean) {
	setOpenSubMenus((currSubmenu) => {
		let updatedSubmenu: {[key: number] : boolean} = {};
		for (let menuKey in currSubmenu) {
			updatedSubmenu[parseInt(menuKey)] = menuKey === id.toString() ? forceOpen ? true : !currSubmenu[id] : false
		}
		return updatedSubmenu;
	})
    setMenuShow(true);
    setActive(false);

    if (debouncedSearchText.length === 0 && (forceOpen || !openSubMenus[id])) {
		let selectedFolder = params.boardType === 'order_final' ? id : name;
		getBoardsByFolder(selectedFolder, params.boardType).then((data) => {
			if (data.error && data.error === 'Invalid authorization') {
				redirectToLogin();
			} else {
				// Create the submenu object as { language : data array }
				let object: any = {};
				object[name] = data.data;
				setSubMenu(object);
			}
		});
    }
  }

  //NOTE: this behaviour may need to be reviewed
  useEffect(() => {
    if (myRef.current) {
		myRef.current?.scrollIntoView();
	  }
  }, [subMenu]);

//initialize folders
  useEffect(() => {
	if (params.boardType && params.boardType !== 'stash' && params.boardType !== 'all_websites') {
		initFolders();
	}
	// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.boardType]);

  const initFolders = () => {
	setSearchText('');
	getAllFolders(params.boardType).then((data) => {
		if (data.error && data.error === 'Invalid authorization') {
			redirectToLogin();
		} else {
			setMenu([...data.data]);
			initSubMenu(data.data);		
			if (history.location.state && (history.location.state as RefreshSignal).openFolderId && (history.location.state as RefreshSignal).openFolderName) {
				openFolder((history.location.state as RefreshSignal).openFolderName as string, (history.location.state as RefreshSignal).openFolderId as number, true);
				setMenuShow(true);
			} else {
				setMenuShow(false);
			}
		}
	  });
  }

  const initSubMenu = (data: SubMenuData[]) => {
	let menuStatus: {[key: number] : boolean} = {};
	for (let item of data) {
		menuStatus[item.id] = false;
	}
	setOpenSubMenus(menuStatus);
  }

  //go to accont managment 
  const goToAcMng = () => {
    history.push(`/account/management`);
  }

  //call search api
  useEffect(() => {
	if (params.boardType === 'stash') {
		setMenu([{id: 0, name: 'Stash', created_at: (new Date()).toUTCString()}])
	} else if (params.boardType === 'all_websites') {
		setMenu([{id: 0, name: 'All Websites', created_at: (new Date()).toUTCString()}])
	} else if (params.boardType) {
		if (debouncedSearchText.length > 0) {
			sideBarApiSearch(debouncedSearchText, params.boardType).then(
			  (data) => {
				let tempMenu = [];
				let tempSubMenu: any = {};
				let subMenuStatus: {[key: string] : boolean} = {};
				for (let item of data.data) {
					tempMenu.push({
						id: item.id,
						name: item.name,
						created_at: 'sdasd',
					});
					subMenuStatus[item.id] = false;
					tempSubMenu[item.name] = item.items;
				}
	  
				// Create the new menu list
				setMenu([...tempMenu]);
	  
				// Create the submenu object as { language : data array }
				setSubMenu(tempSubMenu);

				setOpenSubMenus(subMenuStatus);
			  }
			);
		  } else if (debouncedSearchText.length === 0) {
			getAllFolders(params.boardType).then((data) => {
			  setMenu([...data.data]);
			  initSubMenu(data.data);
			  setMenuShow(false);
			});
		  }
	}
	// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchText]);

  

  const getPages = () => {
	let userInfo : UserInfo  = JSON.parse(localStorage.getItem('user_info') as string);
	const selectItems = [];
	if (userInfo.role_id === 2) {
		selectItems.push({ label: 'Stash', value: 'stash' });
		selectItems.push({ label: 'All Websites', value: 'all_websites' });
	}
	selectItems.push({ label: 'Links Availability', value: 'links_availability' });
	selectItems.push({ label: 'Historical', value: 'historical' });
	selectItems.push({ label: 'Order Final', value: 'order_final' });
	return selectItems;
  }

  function handlePageSelect(selectedPage: SingleValue<menuPageOption>, actionMeta: ActionMeta<menuPageOption>) {
	//TODO EVG: consider remembering the previously selected id here
	setSearchText('');
	history.push('/boards/' + selectedPage?.value);
  }

  useEffect(() => {
	if (history.location.state && (history.location.state as RefreshSignal).refreshSidebar) {
		initFolders();
	}
	// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location])

  
  return (
    <>
      <div onClick={onClickMenuIcon} className="btn-toggle toggleBtn">
        <FontAwesomeIcon icon={faBarsStaggered} />
      </div>

      <ProSidebar style={styles.sideBarHeight} collapsed={collapsed}>
        <SidebarHeader>
          <Menu>
            <div
              style={styles.menuIcon}
              onClick={() => onClickMenuIcon()}
              className="inlineLogo"
            >
              {sideShow ? (
                // <img
                //   src="../logo.png"
                //   alt=""
                //   width={'100%'}
                //   height={'60px'}
                // />
                <img src="/logo.png" alt="" width="90%" height="auto" />
              ) : null}
              <FontAwesomeIcon icon={faBarsStaggered} />
            </div>
          </Menu>
        </SidebarHeader>
        <SidebarContent>
          <Menu>
            <p>{sideShow}</p>
          </Menu>

          <Menu>
            {sideShow ? (
              <>
                {' '}
                <div className="topOption">
                  <button className="btn btn-info" onClick={() => goToAcMng()}>
                    Account Management
                  </button>
                </div>
                <Menu>
                  <div className="selectDesign">
                    <Select
					  placeholder={'Select view'}
                      options={getPages()}
                      defaultValue={getPages().filter(
                        (option) =>
                          option.value === 'links_availability'
                      )}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary25: '#0c1e35',
                          neutral0: '#0c1e35',
                          neutral5: 'red',
                          primary: '#eee',
                        },
                      })}
					  value={getPages().filter(
                        (option) =>
                          option.value === params.boardType
                      )}
					  onChange={handlePageSelect}
                    />
                  </div>
				  {params.boardType && params.boardType !== 'stash' ? <input
                    className="nosubmit form-control"
                    value={searchText}
                    type="search"
                    placeholder="Search..."
                    onChange={(e) => setSearchText(e.target.value)}
                  /> : ''}
                </Menu>
              </>
            ) : (
              <Menu className="Abtn">
                <button className="btn accountBtn">A</button>
              </Menu>
            )}
          </Menu>

          <Menu iconShape="square">
            {params.boardType ? (boardMap[params.boardType] && boardMap[params.boardType].type === 'single') ? (
				<MenuItem
					key={1}
					icon={<FontAwesomeIcon icon={faRectangleList} />}
				>
					<Link to={`/boards/${params.boardType}`} >
						{boardMap[params.boardType].name}
					</Link>
				</MenuItem>
				) : menu.map((item, i) => (
             
                <SubMenu
					// key={i}
				  ref={openSubMenus[item.id] ? myRef : null}	
                  title={item.name}
				  onOpenChange={(open) => openFolder(item.name, item.id, false)}
                  key={'subMenu_item_' + params.boardType + '_' + item.name}
                  icon={<FontAwesomeIcon icon={faFolderOpen} />}
				  defaultOpen={false}
				  open={openSubMenus[item.id]}
                >
                  {menuShow ? (
                    <>
                      {' '}
                      {item.name in subMenu ? (
                        <>
                          <LoadingOverlay active={isActive} spinner>
                            <div>
                              {subMenu[item.name].map(
                                (sub: any, j: any) => (
                                  <MenuItem
                                    key={j}
                                    icon={
                                      <FontAwesomeIcon
                                        icon={faBorderAll}
                                      />
                                    }
                                  >
									<Link to={`/boards/${params.boardType}/${sub.id}`} >
                                    	{sub.name}
									</Link>
                                  </MenuItem>
                                )
                              )}
                            </div>
                          </LoadingOverlay>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : null}
                </SubMenu>
              
            )) : ''}
          </Menu>
        </SidebarContent>
      </ProSidebar>
    </>
  );
};

export default Sidebar;
