import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { COLUMN_TYPE, OPTIONS } from './enum';

interface FilterProps {
    // viewSearch: (value: any, index: number, name: string) => Promise<void>;
    // setRenameViewId: (value: any) => void;
    removeFields: (index: number) => void;
    selectSortType: (valur: any, index: number) => void;
    selectColumn: (value: any, index: number) => void;
    selectFilterValue: (data: any, index: number) => void;
    input: any;
	index: number;
    tableFilterColumns: Array<SelOpt>;
    // i: number
    // option: OPTIONS;
    // optionType: COLUMN_TYPE;
}

type SelOpt = {
	value: string, 
	label: string,
	type?: string
}
type CompareOptions = Array<{value: string, label: string}>

const FilterBox = ({ input, index, removeFields, selectSortType, tableFilterColumns, selectColumn, selectFilterValue}: FilterProps) => {
	const stringOptions: CompareOptions = [
		{
		  value: OPTIONS.CONTAIN,
		  label: 'Contain',
		},
		{
		  value: OPTIONS.DOES_NOT_CONTAIN,
		  label: 'Not Contain',
		},
		{
		  value: OPTIONS.IS,
		  label: 'Is',
		},
		{
		  value: OPTIONS.IS_EMPTY,
		  label: 'Is Empty',
		},
		{
		  value: OPTIONS.IS_NOT,
		  label: 'Is Not',
		},
		{
		  value: OPTIONS.IS_NOT_EMPTY,
		  label: 'Is Not Empty',
		}
	  ];

	const numberOptions: CompareOptions = [
		{
		  value: COLUMN_TYPE.EQUAL,
		  label: '=',
		},
		{
		  value: COLUMN_TYPE.NOT_EQUAL,
		  label: '!=',
		},
		{
		  value: COLUMN_TYPE.LESS_EQUAL,
		  label: '>=',
		},
		{
		  value: COLUMN_TYPE.LESS,
		  label: '>',
		},
		{
		  value: COLUMN_TYPE.GREATER_EQUAL,
		  label: '<=',
		},
		{
		  value: COLUMN_TYPE.GREATER,
		  label: '<',
		},
		{
		  value: COLUMN_TYPE.IS,
		  label: 'Is',
		},
		{
		  value: COLUMN_TYPE.IS_EMPTY,
		  label: 'Is Empty',
		},
		{
		  value: COLUMN_TYPE.IS_NOT,
		  label: 'Is Not',
		},
		{
		  value: COLUMN_TYPE.IS_NOT_EMPTY,
		  label: 'Is Not Empty',
		},
	  ];

	function getSortValue() {
		if (input.sortOptions) {
			if (input.columnTypes === "number") {
				return numberOptions.find((opt) => opt.value === input.sortOptions)
			} else {
				return stringOptions.find((opt) => opt.value === input.sortOptions)
			}
		}
		return null
	}  

	return (
        <>
            {/* 2nd */}
            <div className="col-md-3">
                <Select
					key={`filter_col_${index}`}
                    onChange={(data) =>
                        selectColumn(data, index)
                    }
                    aria-required={true}
                    options={tableFilterColumns}
					isMulti={false}
                    value={input.columnName ? { label: tableFilterColumns.find((col) => col.value === input.columnName)?.label, value: input.columnName } : null}
                />
            </div>
            {/* 3rd */}
            <div className="col-md-3">
                <span>
					<Select
						key={`filter_compare_${index}_${input.columnTypes}`}
						onChange={(data) =>
							selectSortType(data, index)
						}
						options={input.columnTypes === "number" ? numberOptions : stringOptions}
						isDisabled={
							input.columnName.length
								? false
								: true
						}
						value={getSortValue()}
						aria-required={true}
                	/>
				</span>
            </div>
            {/* 4th */}
            <div className="col-md-3">
                {
                    input.sortOptions ===
                        OPTIONS.CONTAIN ||
                        input.sortOptions ===
                        OPTIONS.DOES_NOT_CONTAIN ? (
                        <div>
                            <input
								key={`filter_val_${index}_${input.columnTypes}_${input.sortOptions}`}
                                type='text'
                                className="form-control"
                                placeholder="value"
                                onChange={(e) =>
                                    selectFilterValue(
                                        e.target.value,
                                        index
                                    )
                                }
                                disabled={
                                    input.sortOptions.length
                                        ? false
                                        : true
                                }
                                value={input.value ? input.value : ''}
                            />
                        </div>
                    ) : input.sortOptions ===
                        OPTIONS.IS_EMPTY ||
                        input.sortOptions ===
                        OPTIONS.IS_NOT_EMPTY ? (
                        <></>
                    ) : input.sortOptions ===
                        COLUMN_TYPE.EQUAL ||
                        input.sortOptions ===
                        COLUMN_TYPE.NOT_EQUAL ||
                        input.sortOptions ===
                        COLUMN_TYPE.LESS_EQUAL ||
                        input.sortOptions ===
                        COLUMN_TYPE.LESS ||
                        input.sortOptions ===
                        COLUMN_TYPE.GREATER_EQUAL ||
                        input.sortOptions ===
                        COLUMN_TYPE.GREATER ? (
                        <div>
                            <input
								key={`filter_val_${index}_${input.columnTypes}_${input.sortOptions}`}
                                type='text'
                                placeholder="number"
                                className="form-control"
                                onChange={(e) =>
                                    selectFilterValue(
                                        e.target.value,
                                        index
                                    )
                                }
                                disabled={
                                    input.sortOptions.length
                                        ? false
                                        : true
                                }
                                value={input.value ? input.value : ''}

                            />
                        </div>
                    ) : (
                        <div>
                            <Select
								key={`filter_val_${index}_${input.columnTypes}_${input.sortOptions}`}
                                isMulti
                                name="colors"
                                options={input.valueOptions}
                                value={input.value ? input.value.map((el:string) => { return { value: el, label: el }}) : []}
                                onChange={(data) =>
                                    selectFilterValue(data, index)
                                }
                                className="basic-multi-select"
                                classNamePrefix="select"
                                isDisabled={
                                    input.sortOptions.length
                                        ? false
                                        : true
                                }
                                aria-required={true}
                            />
                        </div>
                    )

                }
            </div>
            <div className="col-md-1 closeBtn">
                <p onClick={() => removeFields(index)}>
                    <FontAwesomeIcon icon={faClose} />
                </p>
            </div>
        </>
    )
}

export default FilterBox