import React from 'react';
import { Switch, Route, Redirect, useParams, useRouteMatch } from 'react-router-dom';
import { isAuthenticate } from './shared/services/api';
import LinksAvailability from './pages/LinksAvailability';
import Historical from './pages/Historical';
import OrderFinal from './pages/OrderFinal';
import Stash from './pages/Stash';
import AllWebsites from './pages/AllWebsites';
import Sidebar from './Component/Sidebar/Sidebar';
import Navbar from './Component/Navbar/Navbar';
import { Container, Row } from 'react-bootstrap';


type URLParams = {
	boardType: string;
  };
function Boards({ component, path, children, ...rest }: any) {
	let { boardType } = useParams<URLParams>(); //not sure if neeeded
	if (isAuthenticate()) {
		return (
			<div className="app">
				<Sidebar />
				<div className='main'>
					<Container className={(boardType !== 'stash' && boardType !== 'all_websites') ? 'tooltipBox' : ''}>
						<Row>
							<Navbar />
							<Switch>
								<Route exact path={`/boards/links_availability/:id`}>
									<LinksAvailability />
								</Route>
								<Route exact path={`/boards/links_availability`}>
									<LinksAvailability />
								</Route>
								<Route exact path={`/boards/stash`}>
									<Stash />
								</Route>
								<Route exact path={`/boards/all_websites`}>
									<AllWebsites />
								</Route>
								<Route exact path={`/boards/order_final/:id`}>
									<OrderFinal />
								</Route>
								<Route exact path={`/boards/order_final`}>
									<OrderFinal />
								</Route>
								<Route exact path={`/boards/historical/:id`}>
									<Historical />
								</Route>
								<Route exact path={`/boards/historical`}>
									<Historical />
								</Route>
							</Switch>
						</Row>
					</Container>
				</div>
			</div>
		);
	} else {
		return (
		<Redirect
			to={{
			pathname: '/',
			}}
		/>
		);
	}
}

export default Boards;
