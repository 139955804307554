import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

//get all pages
import Home from './pages/Home';
import LinksAvailability from './pages/LinksAvailability';
import Signin from './pages/Signin';
import AdminRoutes from './AdminRoutes';
import AccountManagement from './Component/AccountManagement/AccountManagement';
import ResetPassword from './Component/ResetPassword/ResetPassword';
import Boards from './Boards';

//TODO EVG check what can be cleaned here, and the Navbar needs to be fixed in the 
//Account Management page. Some reorganization may be needed
const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        {/* <Route path="/" exact component={Home} /> */}
        <Route path="/" exact component={Signin} />
        <Route path="/reset-password" exact component={ResetPassword} />
		<Route path={`/boards/:boardType`} component={Boards} />
        {/* <AdminRoutes
          path="/links_availability/"
          exact
          component={LinksAvailability}
        />
        <AdminRoutes
          path="/links_availability/:id"
          exact
          component={LinksAvailability}
        />
		*/
        <AdminRoutes
          path="/account/management"
          exact
          component={AccountManagement}
        /> }
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
