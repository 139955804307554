import React from 'react'

export enum ModalType {
    DELETE,
    UPDATE
}

interface ModalProps {
    onSave: () => void;
    setRenameView?: (value: string) => void;
    type: ModalType
}

const Modal = ({ onSave, type, setRenameView }: ModalProps) => {
    return (
        <div className="modal fade" id={type === ModalType.DELETE ? "confirmDelete" : "staticBackdrop"} data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby={type === ModalType.DELETE ? "confirmDeleteLabel" : "staticBackdropLabel"} aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">

                    <div className="modal-header">
                        <h5 className="modal-title" id={type === ModalType.DELETE ? "confirmDeleteLabel" : "staticBackdropLabel"}> {type === ModalType.DELETE ? "Delete View" : "Rename the name"}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <div className="modal-body renameBox">
                        {type === ModalType.DELETE ?
                            <>
                                <h1>Do you really want to delete this view?</h1>
                            </> :
                            <>
                                <h1>Enter your update name</h1>
                                <input id='renameView' onBlur={(e) => setRenameView ? setRenameView(e.target.value) : ""} type='text' className='form-control' />
                            </>
                        }
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger" onClick={() => onSave()} data-bs-dismiss="modal"> {type === ModalType.DELETE ? "Yes" : "Save"} </button>
                        <button type="button" className="btn btn-info" data-bs-dismiss="modal">No</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal