import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './TableC.css';
import {
  faPlusCircle,
  faSearch,
  faEllipsis,
  faSave,
} from '@fortawesome/free-solid-svg-icons';
import Modal, { ModalType } from './Modal';
import ViewData from './ViewData';
import FilterBox from './FilterBox';
import { Dropdown } from 'react-bootstrap';
import Select from 'react-select';
import {
	removeView,
	views,
	getViews,
	renameViewApi
} from '../../shared/services/api';
import {
	FilterColOptions,
	ViewsType,
	TableDataRow
  } from './interface';

interface FiltersProps {
	columns: FilterColOptions[];
	tableData: TableDataRow[];
	type: string;
	tableId?: number | string;
	dataLoading: boolean;
	onChangeFilters: (value: any) => Promise<void>;
}

interface FilterProps {
	columnName: string;
	sortOptions: string;
	value: Array<string|number>;
	condition: 'AND' | 'OR' | '';
	columnTypes: 'number' | 'string' | 'percent';
}

const FiltersC = ({onChangeFilters, columns, tableData, type, tableId, dataLoading}: FiltersProps) => {
	const [viewData, setViewData] = useState<ViewsType[]>([]);
	const [renameView, setRenameView] = useState<string>('');
	const [renameViewId, setRenameViewId] = useState<number>(-1);
	const [deleteViewId, setDeleteViewId] = useState<number>(-1);
	const [isDataActive, setDataActive] = useState(-1);
	const [show, setShow] = useState(false);
	const [optionAdd, setOptionAdd] = useState<string>('');
	const [tableFilterColumns, setTableFilterColumns] = useState<FilterColOptions[]>([]);
	const [name, setNameView] = useState('');
	const [addInput, setNaminput] = useState(false);
	const [dropDownError, setDropDownError] = useState(false);
	const [saveNameInput, setSaveNameInput] = useState(false);

	const [filterOptions, setFilterOptions] = useState<any[]>([
		{
		columnName: '',
		sortOptions: '',
		value: [],
		valueOptions: [],
		columnTypes: '',
		condition: '',
		},
	]);

  const [opeartionType, setOpeartionType] = useState<any[]>([
	{ label: 'AND', value: 'AND' },
	{ label: 'OR', value: 'OR' },
  ]);

  useEffect(() => {
	processColumns();
	setViewButtonsData();
	setNewFilterOptions(-1, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
	setNewFilterOptions(-1, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableId]);


  const processColumns = () => {
	let filterTempData: FilterColOptions[] = [];
	columns.forEach((col) => {
		filterTempData.push({
            value: col.value,
            label: col.label,
            type: col.type
		});
	})
	setTableFilterColumns(filterTempData);
  }

  const selectColumn = (data: any, index: number, newTableData:any[]=[]) => {    
	let filterObject = [...filterOptions]
	for (let i = 0; i < filterObject.length; i++) {
	  if (i === index) {
		filterObject[i].columnName = data.value;
		if (filterObject[i].columnTypes != data.type) {
			filterObject[i].value = [];
		}
		filterObject[i].columnTypes = data.type;
		//Temp, reworking
		filterObject[i].valueOptions = getUniqueValues1(!newTableData.length ? tableData : newTableData, data.value)
	  }
	}
	setFilterOptions(filterObject);
  };

	const getUniqueValues1 = (tableData: TableDataRow[], columnName: any) => {
		let uniqueValues = new Set();
		tableData.forEach((row) => {
			uniqueValues.add(row[columnName])
		})
		let result : any[] = []; 
		uniqueValues.forEach((val) => result.push({label: val, value: val}));
		return result;
	}

  const selectCondition = (data: any, index: number) => {
	let filterObject = [...filterOptions];
	for (let i = 1; i < filterObject.length; i++) {
	  if (i === index) {
		filterObject[i].condition = data.value;
		setOptionAdd(data.value);
	  } else {
		filterObject[i].condition = data.value;
		setOptionAdd(data.value);
	  }
	}
	setFilterOptions(filterObject);
  };

  const selectSortType = (data: any, index: number) => {
    let filterObject = [...filterOptions];
    for (let i = 0; i < filterObject.length; i++) {
      if (i === index) {
        filterObject[i].sortOptions = data.value;
      }
    }
    setFilterOptions(filterObject);
  };

  const selectFilterValue = (data: any, index: number) => {
    let filterObject = [...filterOptions];
    let obj = [];

    if (typeof data === 'string') {
      if (obj.length === 0) {
        obj.push(data);
      } else {
        obj[0] = data;
      }
    } else {
      for (let j = 0; j < data.length; j++) {
        obj.push(data[j].value);
      }
    }

    for (let i = 0; i < filterObject.length; i++) {
      if (i === index) {
        filterObject[i].value = obj;
      }
    }
    setFilterOptions(filterObject);
  };

	const removeFields = (index: number) => {
		let data = [...filterOptions];
		data.splice(index, 1);
		setFilterOptions([...data]);
	};

	const addFields = () => {
		setFilterOptions([
		  ...filterOptions,
		  { columnName: '', sortOptions: '', value: '', condition: optionAdd ? optionAdd : '', valueOptions: [] },
		]);
	  };

	const addName = () => {
		setNaminput(!addInput)
	  }
	
	  // save view name
	  const handleChange = (e: any) => {
		setNameView(e.target.value);
	  };

	  const saveName = () => {
	
		if (name.length === 0 || !verfiyFilters()) {
		  setDropDownError(true);
		  return;
		}

		const finaldata = {
		  "name": name,
		  filterOptions: filterOptions,
		  "type": type
		}
	
		const indexViewSaved = viewData.findIndex(item => item['name'] === finaldata['name']);
	
		// setSearchLoading(true);
		views(finaldata).then(data => {
		  setViewButtonsData()
		//   setSearchLoading(false);
		//   setIsActive(!isActive);
		  if (!name) setNameView("");
		  setNaminput(!addInput);
		  setDropDownError(false);
	
		  viewSearch(data['data']['filters'], indexViewSaved === -1 ? viewData.length : indexViewSaved, data['data']['name']);
		})
	
		toggleShow()
	
	  };

	  const setViewButtonsData = () => {
		getViews(type).then(data => {
		  setViewData([...data.data]);
		})
	  }

	  const toggleShow = () => {
		setShow(!show);
	  }
	

	  const setNewFilterOptions = (index: number, refresh: boolean = true) => {
		setDataActive(index);
		setFilterOptions([]);
		// setLoading(true);
		setOptionAdd("");
		// setIsActive(false);
		setDropDownError(false);
		setNameView('');
		if (refresh) {
			onChangeFilters([]);
		}
	  };

	  const viewSearch = async (value: any, index: number, name: string) => {
		setSaveNameInput(true)
		setNameView(name);
		setDataActive(index);
	
		console.log('value :', value)
		console.log('index :', index)
	
		await onChangeFilters(value).then(() => {
			const updatedFilters = [];
			for (const filter of value as FilterProps[]) {
				let filterData = {
					...filter, //give proper type
					valueOptions: getUniqueValues1( tableData, filter.columnName)
				}
				updatedFilters.push(filterData);
			}
			setFilterOptions(updatedFilters);
		})
	
		// setFilterOptions([...data]);
	  }


	  

		const deleteView = () => {

			removeView(deleteViewId).then(data => {
			  setNewFilterOptions(-1);
			  setViewButtonsData();
			  setDeleteViewId(-1)
			})
		  }
		
		  const renameSave = () => {
			renameViewApi(renameViewId, renameView).then(data => {
			  const result = document.getElementById('renameView') as HTMLInputElement | null;
			  if (result != null) {
				result.value = '';
			  }
			  setNewFilterOptions(-1);
			  setViewButtonsData();
			  setRenameViewId(-1);
			  setRenameView('');
			})
		  }

		 const handleFilterSearch = async () => {
			if (verfiyFilters()) {
				setDropDownError(false);
				onChangeFilters(filterOptions);
			} else {
				setDropDownError(true);
			}	
		 }

		 //May not be needed
		 const verfiyFilters = () => {
			if (filterOptions.length === 0) {
				// setDropDownError(true);
				return false;
			}
			for (let i = 0; i < filterOptions.length; i++) {
				// data[i] = {
				// 	column: filterOptions[i].columnName,
				// 	sortOptions: filterOptions[i].sortOptions.toLowerCase(),
				// 	value: filterOptions[i].value,
				// 	condition: filterOptions[i].condition,
				// 	code : filterOptions[i].code
				// };
				if (filterOptions[i].columnName.length === 0 || filterOptions[i].sortOptions.length === 0) {
					// setDropDownError(true);
					return false;
				}
				if (i > 0 && filterOptions[i].condition.length === 0) {
					// setDropDownError(true);
					return false;
				}
				if (filterOptions[i].value.length === 0 && filterOptions[i].sortOptions !== "isempty" && filterOptions[i].sortOptions !== "isnotempty") {
					// setDropDownError(true);
					return false;
				}
			}
			return true;
		 }
		

	return (
		<>
			<div className="btn-group filterBtn">
				<div className="d-flex dropdown">

					<Dropdown show={show} onToggle={(isOpen) => setShow(isOpen)} className='dropdownFilter' id='filersPanel'>
						<Dropdown.Toggle className="btn filterButton">
							Filter
							{filterOptions.length > 0
							? filterOptions[0].columnName.length > 0
								? ' / ' + filterOptions.length
								: ''
							: ''}
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<div className=""
							>
							<div className="container mb-3">
								<div className="row mt-2 mb-3">
									<div className="col-md-6 filterHeading">
										<p>Advanced Filters</p>
									</div>
									<div className="col-md-6 clearBtn">
										<button
											className="btn non-btn"
											onClick={() => setNewFilterOptions(-1)}
											>
											Clear all
										</button>
									</div>
								</div>
								<div className="">
									<p className='text-danger text-center'>{dropDownError ? "All Fields are Required" : ""}</p>
									{filterOptions.map((input, index) => {
										return index > 0 ? (
										<div key={`filterOptions_${index}`}>
											<div className="row mt-3 mb-3">
												<div className="col-md-2">
													{
													input.condition.length === 0 ?
														<Select
															onChange={(data) =>
																selectCondition(data, index)
															}
															options={opeartionType}
															value={opeartionType.filter(
																(option) =>
																option.value === optionAdd
															)}
															aria-required={true}
														/>
														:
														<Select
															onChange={(data) =>
																selectCondition(data, index)
															}
															options={opeartionType}
															value={{ label: input.condition.toUpperCase(), value: input.condition }}
															aria-required={true}
														/>
													}
												</div>
												<FilterBox index={index} input={input} removeFields={removeFields} selectSortType={selectSortType} tableFilterColumns={columns} selectColumn={selectColumn} selectFilterValue={selectFilterValue} />
											</div>
										</div>
										) : (
										<div className="row" key={`filterOptions_${index}`}>
											<div className="col-md-2 text-center">
											<p>Where</p>
											</div>
											<FilterBox input={input} index={0} removeFields={removeFields} selectSortType={selectSortType} tableFilterColumns={columns} selectColumn={selectColumn} selectFilterValue={selectFilterValue} />

										</div>
										);
									})}
								</div>

								<div className="row">
									<div className="col-md-12 addBtn mt-3 bottomBox">
										<button
											className="addFilter"
											onClick={addFields}
											>
											Add new filter{' '}
											<FontAwesomeIcon icon={faPlusCircle} />
										</button>

										{saveNameInput ?
										<><input className='nameInput' type="text" name='message' id='message' value={name} onChange={handleChange} placeholder='Name' />
											<button className="addName" onClick={saveName}>Save</button></> :
										<>{addInput ? <>
											<input className='nameInput' type="text" name='message' id='message' value={name} onChange={handleChange} placeholder='Name' />
											<button className="addName" onClick={saveName}>Save</button>
										</> : <>
											<button className="addName" onClick={addName} >
											<FontAwesomeIcon icon={faSave} /> Save Filter
											</button>
										</>}
										</>}
										<button
											className="btn searchBtn non-btn"
											onClick={handleFilterSearch}
											>
											{' '}
											<FontAwesomeIcon icon={faSearch} /> Search
										</button>
									</div>
								</div>
							</div>
							</div>

						</Dropdown.Menu>
					</Dropdown>

					<div className="dataFlex d-flex align-items-center">
					<button disabled={dataLoading} className={isDataActive === -10 ? 'dataActive' : 'btn dataBtn'} onClick={() => setNewFilterOptions(-10)}>Main</button>
					{viewData.length > 0 ? <>
						{viewData.length < 4 ?
						<>
							{viewData.map((v, i) =>
							<div key={`viewData_${i}`} className={isDataActive === i ? 'dataActive' : 'btn dataBtn'}>
								<ViewData disabled={dataLoading} v={v} i={i} isDataActive={isDataActive} viewSearch={viewSearch} setRenameViewId={setRenameViewId} setDeleteViewId={setDeleteViewId} />
							</div>
							)}

						</> :
						<>
							{viewData.map((v, i) => i < 3 ?
							<>
								<div key={`viewData_${i}`} className={isDataActive === i ? 'dataActive' : 'btn dataBtn'}>
								<ViewData disabled={dataLoading} v={v} i={i} isDataActive={isDataActive} viewSearch={viewSearch} setRenameViewId={setRenameViewId} setDeleteViewId={setDeleteViewId} />
								</div>
							</>
							: null)}

							<button className="btn dropdataBtn" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
							<FontAwesomeIcon icon={faEllipsis} />
							</button>

							<ul className="dropdown-menu dropdown-menu-middle dataDropdown scroll" aria-labelledby="dropdownMenuButton2">
							{viewData.map((v, i) => i >= 3 ? <li key={`viewData_${i}`}>
								<div className={isDataActive === i ? 'dropdown-item-active' : 'dropdown-item'}>
								<ViewData disabled={dataLoading} v={v} i={i} isDataActive={isDataActive} viewSearch={viewSearch} setRenameViewId={setRenameViewId} setDeleteViewId={setDeleteViewId} />
								</div>
							</li > : null)}
							</ul >
						</>
						}
					</> : <></>}

					<Modal onSave={renameSave} setRenameView={setRenameView} type={ModalType.UPDATE} />
					<Modal onSave={deleteView} type={ModalType.DELETE} />
					</div >
				</div >
			</div >
		</>

	)
}

export default FiltersC;