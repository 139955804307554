import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpLong, faDownLong } from '@fortawesome/free-solid-svg-icons';
import { SORT_TYPE } from './enum';
import {
	TableDataRow
  } from './interface';


interface tableDataProps {
    tableDataList: (data: TableDataRow[]) => JSX.Element[];
	data: TableDataRow[];
	show: boolean,
	editable: boolean,
    renderDynamicColumn: () => JSX.Element[];
    sortNameColumn: () => Promise<void>;
    type: SORT_TYPE;
}

const TableData = ({ type, data, show, editable, tableDataList, renderDynamicColumn, sortNameColumn }: tableDataProps) => {
    return (
        <>
            <div className={'table-responsive tBorder dataTableCont' + (!show ? ' closed' : '')}>
                <table className="table table-responsive table-borderedless text-center table-fixed">
                    <thead className="sticky-top tableDropdown">
                        <tr>
							{editable ? (<th className="sticky-cell">Edit</th>) : null}
                            <th className="sticky-cell" onClick={() => sortNameColumn()}>
                                Name{' '}
                                <span>
                                {type === SORT_TYPE.NA ||
                                  type === SORT_TYPE.DESC ? (
                                  <FontAwesomeIcon icon={faUpLong} />
                                ) : (
                                  <FontAwesomeIcon icon={faDownLong} />
                                )}
                                </span>
                            </th>
                            {renderDynamicColumn()}
                        </tr>
                    </thead>
                    <tbody>
                        {tableDataList(data)}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default TableData